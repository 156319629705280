const questions = [
    {
      question: "Η σχέση μου με το νερό. Μες τη μέρα πίνω:",
      answers: ["1. Πολύ", "2. Αρκετά ", "3. Πολύ λίγο"]
    },
    {
      question: "Χρησιμοποιώ μάσκα μαλλιών στο λούσιμο:",
      answers: ["1. Κάθε φορά ", "2. Συχνά", "3. Σπάνια"]
    },
    {
      question: "Χρησιμοποιώ conditioner στο λούσιμο:",
      answers: ["1. Κάθε φορά ", "2. Συχνά", "3. Σπάνια"]
    },
    {
      question: "Χρησιμοποιώ εργαλεία θερμότητας στην καθημερινότητά μου (πιστολάκι, ψαλίδι, πρέσα):",
      answers: ["1. Σχεδόν ποτέ", "2. Αρκετά συχνά", "3. Συνέχεια"]
    },
    {
        question: "Βάζω προϊόντα περιποίησης μετά το λούσιμο:",
        answers: ["1. Κάθε μέρα", "2. Καμιά φορά", "3. Σπάνια/Ποτέ"]
      },
      {
        question: "Πηγαίνω για κούρεμα:",
        answers: ["1. Τακτικά (6 φορές τον χρόνο)", "2. Αραιά (4 φορές τον χρόνο)", "3. Πολύ αραιά (2 φορές τον χρόνο)"]
      },
      {
        question: "Κάνω θεραπείες στα μαλλιά μου:",
        answers: ["1. Συχνά", "2. Σπάνια", "3. Σχεδόν ποτέ/ποτέ"]
      },
      {
        question: "Τρώω φρούτα/λαχανικά/ξηρούς καρπούς/ δημητριακά:",
        answers: ["1. Κάθε μέρα", "2. Τακτικά", "3. Αραιά"]
      },
      {
        question: "Χρησιμοποιώ προϊόντα styling στα μαλλιά μου:",
        answers: ["1. Συχνά", "2. Αραιά", "3. Σχεδόν ποτέ/Ποτέ"]
      },
      {
        question: "Κάνω αλλαγές χρώματος στα μαλλιά μου:",
        answers: ["1. Αραιά", "2. Συχνά", "3. Συνέχεια"]
      },
      {
        question: "Τα μαλλιά μου μακραίνουν:",
        answers: ["1. Εύκολα", "2. Αργούν λίγο", "3. Αργούν πολύ"]
      },
      {
        question: "Έχω ψαλίδα:",
        answers: ["1. Ελάχιστη", "2. Αρκετή", "3. Πολλή"]
      },
      {
        question: "Όταν αγγίζω τα μαλλιά μου τα νιώθω:",
        answers: ["1. Μαλακά", "2. Αφυδατωμένα", "3. Άγρια"]
      },
  ];
  
  export default questions;