import React, { useState } from 'react';
import './Quizz.css';
import questions from '../data/questions';

const Quiz = () => {
  // state to keep track of the current question
  const [currentQuestion, setCurrentQuestion] = useState(0);
  
  // state to keep track of the user's answers
  const [answers, setAnswers] = useState([]);

  // list of quiz questions and answers
  

  // function to handle when the user selects an answer
  const handleSelectAnswer = (answer) => {
    // define the starting number of the answer
    answer = answer.slice(0,1)
    // console.log(answer);
    // add the answer to the list of answers
    setAnswers([...answers, answer]);
    // move to the next question
    setCurrentQuestion(currentQuestion + 1);
  }

  const handleRestart = () => {
    setCurrentQuestion(0);
    setAnswers([]);
  }

  // function to handle when the user finishes the quiz
  const handleFinishQuiz = () => {
let mf = 1;
let m = 0;
let item;
for (let i=0; i<answers.length; i++)
{
        for (let j=i; j<answers.length; j++)
        {
                if (answers[i] === answers[j])
                 m++;
                if (mf<m)
                {
                  mf=m; 
                  item = answers[i];
                }
        }
        m=0;
}
// console.log(`${item} ( ${mf} times ) `) ;

    let result = '';
    let resultP = '';

    switch (item) {
      case "1":
      result = "Hair TOUCH-UP"
      resultP = "Μπράβο για την προσπάθειά σας να διατηρείτε υγιή τα μαλλιά σας. Όμως, χρειάζεστε ενίσχυση! Οι θεραπείες της σειράς HAIR TOUCH-UP θα χαρίσουν ένα boost αναζωογόνησης στα μαλλιά σας κάνοντάς τα λαμπερά και μεταξένια!"
      break
      case "2":
      result = "Hair RESTORE"
      resultP = "Έχουμε δουλειά! Τα μαλλιά σας διψούν για φροντίδα. Οι θεραπείες της σειράς Hair RESTORE θα σβήσουν το κακό παρελθόν των μαλλιών σας και θα χτίσουν την ποιότητα της τρίχας από την αρχή!"
      break
      case "3":
      result = "Hair  RESTORE ADVANCED"
      resultP = "Έχουμε πολλή δουλειά. Τα μαλλιά σας είναι αρκετά παραμελημένα και διψούν για περιποίηση. Οι θεραπείες της σειράς Hair Restore ADVANCED θα σβήσουν το κακό παρελθόν των μαλλιών σας και θα βοηθήσουν την τρίχα να αναδομηθεί. Μην ανησυχείτε, όλα διορθώνονται."
      break
      default:
      break;
  }
  
  // show the result
  return <div className="content"><h2>{"Η κατάλληλη Θεραπεία είναι: " + result}</h2><p>{resultP}</p><button type="button" className="btn btn-warning" onClick={handleRestart}>"Επανάληψη Ερωτηματολογίου"</button>
    </div>

        }

        return (
        <>
            {currentQuestion < questions.length ? (

                

                <div className="content">
                    <div id="quizzScreen">
                    <h3>{"Ερώτηση: "} <strong>{questions.indexOf(questions[currentQuestion])+1+"/"+ questions.length }</strong></h3>
                    </div>
                    <p className='questionPrompt'>{questions[currentQuestion].question}</p>
                    <ul className='answerList'>
                    {questions[currentQuestion].answers.map((answer) => (
                    
                        <li><button type="button" className="btn btn-primary" onClick={() => handleSelectAnswer(answer)} >{answer}</button></li>
                    ))}
                    </ul>
                </div>
            ) : (
                <div>
                    {handleFinishQuiz()}
                </div>
            )}
        </>
        );
        };

        export default Quiz;
  
